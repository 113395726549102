<!--
 * @Author: wang peng
 * @Description:  新增/编辑
-->
<template>
  <ykc-form class="el-form-wrap">
    <!-- 新增 -->
    <div v-if="!isEdit">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="电桩名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字、字符-(1~30字数)，不可重复"
            maxlength="30"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="电桩编码"
          prop="code"
          bottomTip="温馨提示：此为电桩编码，终端编码会在电桩编码后增加两位递增编码，留空自动生成">
          <ykc-input
            placeholder="请输入电桩编码"
            maxlength="14"
            v-model="ruleForm.code"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="归属电站" prop="stationId" v-if="!isEdit">
          <ykc-dropdown
            :clearable="true"
            :data="brandListData"
            v-model="ruleForm.stationId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="维保时间" prop="maintenanceDate">
          <ykc-date-picker
            limitDate="limitBefore"
            format="yyyy-MM-dd"
            v-model="ruleForm.maintenanceDate"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="品牌型号" prop="brandId" v-if="!isEdit">
          <div class="flex">
            <ykc-dropdown
              :clearable="true"
              :data="brandAndModelData"
              v-model="ruleForm.brandId"
              @change="selectChange"
              style="flex: 1; margin-right: 10px"></ykc-dropdown>
            <ykc-button @click="addModelFun">增型号</ykc-button>
          </div>
        </ykc-form-item>
        <ykc-form-item label="充电终端数量" v-if="!isEdit && pileGunNum !== null">
          <div class="flex">{{ this.pileGunNum }}</div>
        </ykc-form-item>
        <ykc-form-item label="车位号" prop="parkNum">
          <ykc-input
            placeholder="请输入车位号"
            maxlength="30"
            v-model="ruleForm.parkNum"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
    <!-- 编辑和详情 -->
    <div v-else>
      <ykc-warm-tip
        show-icon
        type="info"
        description="温馨提示：编辑终端只支持名称和编码编辑，若要调整归属电站或型号，请删除重新创建!"></ykc-warm-tip>
      <ykc-form
        :model="ruleForm0"
        :rules="rules0"
        ref="ruleForm0"
        class="el-form-wrap"
        :disabled="drawerType === 'detail'">
        <ykc-form-item label="终端名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字、字符-(1~30字数)，不可重复"
            maxlength="30"
            :disabled="true"
            v-model="ruleForm0.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="终端编码"
          prop="code"
          class="img-error"
          bottomTip="温馨提醒：终端编码请输入16位纯数字">
          <ykc-input
            placeholder="请输入电桩编码"
            maxlength="16"
            :disabled="true"
            v-model="ruleForm0.code"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="维保时间"
          class="img-error"
          prop="maintenanceDate"
          bottomTip="温馨提示：维保时间修改后会同步至同一电桩下的其他终端">
          <ykc-date-picker
            limitDate="limitBefore"
            format="yyyy-MM-dd"
            v-model="ruleForm0.maintenanceDate"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="车位号" prop="parkNum">
          <ykc-input
            placeholder="请输入车位号"
            maxlength="30"
            v-model="ruleForm0.parkNum"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
    <!-- 合同信息 -->
    <ykc-form
      :model="ruleForm1"
      :rules="rules1"
      ref="ruleForm1"
      class="el-form-wrap"
      :disabled="drawerType === 'detail'">
      <ykc-form-item label="合同日期区间" prop="contractTime">
        <ykc-date-picker format="yyyy-MM-dd" v-model="ruleForm1.contractTime"></ykc-date-picker>
      </ykc-form-item>
      <ykc-form-item label="合同编号" prop="contractCode">
        <ykc-input
          placeholder="请输入合同编号"
          maxlength="50"
          v-model="ruleForm1.contractCode"></ykc-input>
      </ykc-form-item>

      <ykc-form-item label="电能表名称" prop="meterName">
        <ykc-input
          placeholder="请输入电能表名称"
          maxlength="50"
          v-model="ruleForm1.meterName"></ykc-input>
      </ykc-form-item>

      <ykc-form-item label="上传附件" prop="contractUrl">
        <ykc-upload
          type="files"
          :size="1"
          ref="contractUrl"
          :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
          @handleChange="uploadFile"
          @handleRemove="handleRemove('contractUrl')"></ykc-upload>
      </ykc-form-item>
      <ykc-form-item label="建桩联系人" prop="pileName">
        <ykc-input
          placeholder="请输入建桩联系人"
          maxlength="50"
          v-model="ruleForm1.pileName"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="建桩联系人手机号" prop="pilePhone">
        <ykc-input
          placeholder="请输入建桩联系人手机号"
          maxlength="11"
          v-model="ruleForm1.pilePhone"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="取电方式" prop="type">
        <ykc-dropdown
          placeholder="请选择取电方式"
          :data="eleWayData"
          v-model="ruleForm1.type"></ykc-dropdown>
      </ykc-form-item>
      <ykc-form-item label="合同描述" prop="reason">
        <ykc-input
          maxlength="500"
          type="textarea"
          placeholder="请输入合同描述"
          v-model="ruleForm1.reason"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="红包名称">
        <ykc-dropdown
          placeholder="请选择红包"
          :data="redPackData"
          v-model="ruleForm1.redPackTempId"></ykc-dropdown>
      </ykc-form-item>
      <ykc-form-item label="红包有效日期">
        <ykc-date-picker
          type="date"
          format="yyyy-MM-dd"
          :clearable="true"
          limitDate="limitBefore"
          v-model="ruleForm1.periodTime"></ykc-date-picker>
      </ykc-form-item>
    </ykc-form>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import {
    directPage,
    brandModelPage,
    barrierPage,
    uploadUtils,
    privateRedPackApi,
  } from '@/service/apis';

  export default {
    props: {
      gunId: {
        type: Number,
      },
      editInfo: {
        type: Object,
      },
      drawerType: {
        type: String,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        active: 0,
        pileGunNum: null,
        brandAndModel: [], // 终端型号数据
        brandListData: [], // 归属电站数据
        brandAndModelData: [], // 终端型号数据
        redPackData: [], // 红包数据
        ruleForm: {
          name: '',
          code: '',
          stationId: null,
          brandId: null,
          terminalNum: null,
          maintenanceDate: [],
          maintenanceStartDate: '', // 维保开始日期
          maintenanceEndDate: '', // 维保结束日期
          parkNum: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入电桩名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电桩名称'),
            },
          ],
          code: [
            { required: false, message: '请输入电桩编码', trigger: 'blur' },
            { min: 14, max: 14, message: '长度为14位纯数字', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.decimalNumberType_14.regexp,
              message: regexpObj.regexp.input.decimalNumberType_14.errorTips.error(
                '电桩编码',
                '14位纯数字'
              ),
            },
          ],
          stationId: [{ required: true, message: '请选择归属电站', trigger: 'blur' }],
          brandId: [{ required: true, message: '请选择品牌型号', trigger: 'blur' }],
          maintenanceDate: [{ required: false, message: '请选择维保时间', trigger: 'change' }],
          parkNum: [{ required: true, message: '请输入车位号', trigger: 'blur' }],
        },
        ruleForm0: {
          id: null,
          name: '',
          code: '',
          maintenanceDate: [],
          maintenanceStartDate: '', // 维保开始日期
          maintenanceEndDate: '', // 维保结束日期
          parkNum: '',
        },
        rules0: {
          name: [
            { required: true, message: '请输入终端名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType_num1.regexp,
              message: regexpObj.regexp.input.inlineTextType_num1.errorTips.error('电桩名称'),
            },
          ],
          code: [
            { required: true, message: '请输入终端编码', trigger: 'blur' },
            { min: 16, max: 16, message: '长度为16位纯数字', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.decimalNumberType_16.regexp,
              message: regexpObj.regexp.input.decimalNumberType_16.errorTips.error(
                '电桩编码',
                '16位纯数字'
              ),
            },
          ],
          maintenanceDate: [{ required: false, message: '请选择维保时间', trigger: 'change' }],
          parkNum: [{ required: true, message: '请输入车位号', trigger: 'blur' }],
        },
        ruleForm1: {
          id: null,
          contractCode: '',
          meterName: null,
          contractUrl: null,
          contractName: null,
          pileName: null,
          pilePhone: '',
          type: '',
          reason: '',
          contractTime: [],
          contractStartTime: '', // 维保开始日期
          contractEndTime: '', // 维保结束日期
          redPackTempId: '',
          periodTime: '',
        },
        rules1: {
          contractCode: [{ required: false, message: '请输入合同编号', trigger: 'blur' }],
          meterName: [{ required: false, message: '请输入电能表名称', trigger: 'blur' }],
          contractUrl: [{ required: false, message: '请上传合同附件', trigger: 'change' }],
          pileName: [{ required: true, message: '请输入建桩联系人名称', trigger: 'blur' }],
          pilePhone: [
            { required: true, message: '请输入建桩联系人电话', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('联系人电话'),
            },
          ],
          type: [{ required: true, message: '请选择取电方式', trigger: 'blur' }],
          reason: [{ required: false, message: '请输入合同描述', trigger: 'blur' }],
          contractTime: [{ required: true, message: '请选择合同日期区间', trigger: 'change' }],
        },
        // 取电方式
        eleWayData: [
          { id: '1', name: '物业取电' },
          { id: '2', name: '电力报装' },
        ],
      };
    },
    created() {
      // 获取归属电站
      this.requestBrandList();
      this.requestRedPackList();
      this.requestBrandAndModelList();
    },
    mounted() {
      // 编辑回显
      if (this.gunId) {
        console.log('传参++++', this.gunId, this.editInfo);
        this.ruleForm0.id = this.gunId;

        this.ruleForm0.name = this.editInfo.gunName;
        this.ruleForm0.code = this.editInfo.gunCode;
        this.ruleForm0.parkNum = this.editInfo.parkNum;
        const endTime = this.editInfo.maintenanceEndDate;
        const startTime = this.editInfo.maintenanceStartDate;
        if (endTime) {
          this.ruleForm0.maintenanceDate = [startTime, endTime];
        }
        if (this.editInfo.stationPileContractVO.id) {
          this.ruleForm1 = {
            ...this.editInfo.stationPileContractVO,
            type: String(this.editInfo.stationPileContractVO.type),
            contractTime: [
              this.editInfo.stationPileContractVO.contractStartTime,
              this.editInfo.stationPileContractVO.contractEndTime,
            ],
            redPackTempId: this.editInfo.redPackTempId,
            periodTime: this.editInfo.periodTime,
          };
          if (this.editInfo.stationPileContractVO.contractUrl) {
            this.$refs.contractUrl.fileList = [
              {
                url: this.editInfo.stationPileContractVO.contractUrl,
                name: this.editInfo.stationPileContractVO.contractName,
              },
            ];
          }
        }
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          code: this.ruleForm.code,
          stationId: this.ruleForm.stationId,
          brandId: this.ruleForm.brandId,
          terminalNum: this.pileGunNum,
          maintenanceStartDate: this.ruleForm.maintenanceStartDate, // 维保开始日期
          maintenanceEndDate: this.ruleForm.maintenanceEndDate, // 维保结束日期
          parkNum: this.ruleForm.parkNum,
        };
        return formData;
      },
      formData0() {
        const formData0 = {
          id: this.gunId,
          name: this.ruleForm0.name,
          code: this.ruleForm0.code,
          maintenanceStartDate: this.ruleForm0.maintenanceStartDate, // 维保开始日期
          maintenanceEndDate: this.ruleForm0.maintenanceEndDate, // 维保结束日期
          parkNum: this.ruleForm0.parkNum,
        };
        return formData0;
      },
      formData1() {
        const formData1 = {
          id: this.isEdit ? this.editInfo?.stationPileContractVO?.id : '',
          contractCode: this.ruleForm1.contractCode,
          meterName: this.ruleForm1.meterName,
          contractUrl: this.ruleForm1.contractUrl,
          contractName: this.ruleForm1.contractName,
          pileName: this.ruleForm1.pileName,
          pilePhone: this.ruleForm1.pilePhone,
          type: this.ruleForm1.type,
          reason: this.ruleForm1.reason,
          contractStartTime: this.ruleForm1.contractStartTime, // 维保开始日期
          contractEndTime: this.ruleForm1.contractEndTime, // 维保结束日期
          redPackTempId: this.ruleForm1.redPackTempId,
          periodTime: this.ruleForm1.periodTime,
        };
        return formData1;
      },
    },
    methods: {
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findPriOrPubStations({ keyword: '', isPrivatePile: 1 })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            const params = res;
            params.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取品牌型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .brandModelList({})
          .then(res => {
            console.log('获取品牌型号列表+++++', res);
            this.brandAndModel = res ?? [];
            res.map(item => {
              return this.brandAndModelData.push({
                id: item.id,
                name: item.modelName,
                pileGunNum: item.pileGunNum,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求红包列表数据
       */
      requestRedPackList() {
        privateRedPackApi
          .list({ current: 1, size: 9999999 })
          .then(res => {
            res.records?.map(item => {
              return this.redPackData.push({
                id: item.id,
                name: item.redPackName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 选择型号
       * */
      selectChange(e) {
        console.log('选择型号+++', e);
        this.brandAndModel.map(item => {
          if (item.id === e) {
            this.pileGunNum = item.pileGunNum;
          }
          return this.pileGunNum;
        });
      },
      /**
       * @desc 添加型号
       * */
      addModelFun() {
        const routeUrl = this.$router.resolve({
          path: '/plantAssets/brandAndModel/List',
          query: {},
        });
        window.open(routeUrl.href, '_blank');
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm().then(() => {
            this.formData.isPrivatePile = 1;
            if (this.ruleForm1.contractTime.length === 2) {
              this.formData1.contractStartTime = `${this.ruleForm1.contractTime[0]}`;
              this.formData1.contractEndTime = `${this.ruleForm1.contractTime[1]}`;
            }
            if (!this.isEdit) {
              if (this.ruleForm.maintenanceDate.length === 2) {
                this.formData.maintenanceStartDate = `${this.ruleForm.maintenanceDate[0]}`;
                this.formData.maintenanceEndDate = `${this.ruleForm.maintenanceDate[1]}`;
              }
              const data = {
                ...this.formData,
                redPackTempId: this.formData1.redPackTempId,
                periodTime: this.formData1.periodTime,
                stationPileContract: {
                  ...this.formData1,
                },
              };
              directPage
                .dirAdd(data)
                .then(res => {
                  console.log('+++++', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              this.formData0.isPrivatePile = 1;
              if (this.ruleForm0.maintenanceDate.length === 2) {
                this.formData0.maintenanceStartDate = `${this.ruleForm0.maintenanceDate[0]}`;
                this.formData0.maintenanceEndDate = `${this.ruleForm0.maintenanceDate[1]}`;
              }
              const data = {
                ...this.formData0,
                redPackTempId: this.formData1.redPackTempId,
                periodTime: this.formData1.periodTime,
                stationPileContract: {
                  ...this.formData1,
                },
              };
              directPage
                .dirUpdate(data)
                .then(res => {
                  console.log('+++++', res);
                  this.$message({
                    message: '编辑成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        });
      },

      // 删除文件
      handleRemove(propName) {
        this.ruleForm1[propName] = '';
      },
      /** 导入文件 */
      uploadFile(file, fileList, isCheck) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadFile(formData).then(res => {
            this.ruleForm1.contractUrl = res;
            this.ruleForm1.contractName = file.name;
            this.$refs.contractUrl.fileList = [
              {
                name: file.name,
                url: res,
              },
            ];
            this.$refs.ruleForm1.validateField('contractUrl');
          });
        }
      },
    },
  };
</script>
